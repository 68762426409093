import React from 'react';

import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { InternalPageTemplate } from 'ui/templates';

import { mediaPhone } from 'ui/styles/media';

// @ts-ignore
import image from './image.png';

export default function Page404() {
  return (
    <InternalPageTemplate >
      <Root>
        <Picture />
        <Title>Meow! Page not found</Title>
        <Link to="/">← Back to Homepage</Link>
      </Root>
    </InternalPageTemplate>
  );
}

const Title = styled.div`
  font-size: 24px;
  line-height: 1.33;
  font-weight: 500;
  color: var(--grayMiddle);
  text-transform: uppercase;
  ${mediaPhone} {
    font-size: 18px;
  }
`;

const Picture = styled.div`
  width: 734px;
  height: 320px;
  background: no-repeat center url(${image});
  background-size: contain;
  ${mediaPhone} {
    width: 300px;
    height: 130px;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  ${Picture} {
    margin-top: 104px;
    margin-bottom: 32px;
    ${mediaPhone} {
    margin-top: 60px;
    margin-bottom: 16px; 
  }
  }
  ${Title} {
    padding-bottom: 16px;
    ${mediaPhone} {
    padding-bottom: 8px; 
  }
`;
